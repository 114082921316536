import * as React from "react";

import { Button, Card, Checkbox, Stack, TextField } from "@mui/material";
import {
	useGetActivityLogsMutation,
	useLazySaveReportQuery,
} from "../../../services/API/appAPI";

import DateRange from "./DateRange";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Loader from "../Common/Loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import fileDowload from "react-file-download";
import { formatDate } from "../Common/utilityFns";
import usePagination from "../Common/usePagination";

export default function BasicTable() {
	const [activitiesData, setActivitiesData] = React.useState({});
	const [search, setSearch] = React.useState("");
	const [filterRange, setFilterRange] = React.useState([
		dayjs().subtract(1, "month"),
		dayjs(),
	]);
	const [downloadRange, setDownloadRange] = React.useState([
		dayjs().subtract(1, "month"),
		dayjs(),
	]);
	const [isLoading, setIsLoading] = React.useState();
	const [currentPage, entriesPerPage, render, setTotal] = usePagination(0);

	const [getActivies, {}] = useGetActivityLogsMutation();
	const [saveReport, {}] = useLazySaveReportQuery();

	const format = "YYYY-MM-DD";

	React.useEffect(() => {
		fetchActivities();
	}, [currentPage, entriesPerPage]);

	async function fetchActivities(searchTerm = search, range = filterRange) {
		setIsLoading(true);
		const { data } = await getActivies({
			count: entriesPerPage,
			page: currentPage,
			start_date: range[0].format(format),
			end_date: range[1].format(format),
			searchterm: searchTerm,
		});
		if (data) {
			setActivitiesData(data);
			const { totalCount = 0 } = data ?? {};
			setTotal(Number(totalCount));
		}
		setIsLoading(false);
	}

	function onSelectActivity(room) {
		console.log("onselect", room);
	}

	// function getActionButtons(room) {
	// 	const { showEdit = false, showDelete = false } = room;
	// 	return (
	// 		<Stack direction={"row"} spacing={0.5}>
	// 			{showEdit ? (
	// 				<EditIcon
	// 					className='edit-icon'
	// 					onClick={() => navigate("/activity/" + room.id + "/edit")}
	// 				/>
	// 			) : null}
	// 			{showDelete ? (
	// 				<>
	// 					<Divider orientation='vertical' flexItem />
	// 					<DeleteIcon
	// 						className='delete-icon'
	// 						onClick={() => deleteRoomFn(room)}
	// 					/>
	// 				</>
	// 			) : null}
	// 		</Stack>
	// 	);
	// }

	// if (isLoading) return <Loader />;

	const { activities = [] } = activitiesData ?? {};

	return (
		<div className='activity-page'>
			<Loader open={isLoading} />
			<Stack direction={"row"} gap={2}>
				<Card className='card filter-form' sx={{ padding: "1.5rem" }}>
					{/* <div>
					<div>Type</div>
					<FormControl
						sx={{ m: 1, minWidth: 120 }}
						size='small'
						className='select'>
						<InputLabel id='demo-select-small-label-type'>Type</InputLabel>
						<Select labelId='demo-select-small-label-type'>
							<MenuItem value={10}>Ten</MenuItem>
							<MenuItem value={20}>Twenty</MenuItem>
							<MenuItem value={30}>Thirty</MenuItem>
						</Select>
					</FormControl>
				</div> */}
					{/* <Stack direction='row' spacing={2} alignItems={"center"}>
					<div>Action</div>
					<div className='action'>
						<div>
							<Checkbox
								{...{ inputProps: { "aria-label": "Checkbox Active" } }}
								// value={formik.values.create}
								// onChange={formik.handleChange}
								// onBlur={formik.handleBlur}
								// name='create'
								// disabled={!formik.values.all}
							/>
							CREATE
						</div>
						<div>
							<Checkbox
								{...{ inputProps: { "aria-label": "Checkbox Active" } }}
								// value={formik.values.update}
								// onChange={formik.handleChange}
								// onBlur={formik.handleBlur}
								// name='update'
							/>
							UPDATE
						</div>
						<div>
							<Checkbox
								{...{ inputProps: { "aria-label": "Checkbox Active" } }}
								// value={formik.values.delete}
								// onChange={formik.handleChange}
								// onBlur={formik.handleBlur}
								// name='delete'
							/>
							DELETE
						</div>
					</div>
				</Stack> */}
					{/* <div>
					<div>Actor</div>
					<FormControl
						sx={{ m: 1, minWidth: 120 }}
						size='small'
						className='actor select'>
						<InputLabel id='demo-select-small-label-actor'>Actor</InputLabel>
						<Select labelId='demo-select-small-label-actor'>
							<MenuItem value={10}>Ten</MenuItem>
							<MenuItem value={20}>Twenty</MenuItem>
							<MenuItem value={30}>Thirty</MenuItem>
						</Select>
					</FormControl>
				</div> */}
					<div
						className='heading btns'
						style={{ margin: 0 }}
						justifyContent={"center"}>
						<span>FILTERS</span>
					</div>
					<div>
						<div>Search Text</div>
						<TextField
							value={search}
							onChange={(event) => setSearch(event.target.value)}
							size='small'
							label='Search Text'
							variant='outlined'
						/>
					</div>
					<DateRange
						range={filterRange}
						onChange={(range) => setFilterRange(range)}
					/>
					<div className='btns'>
						<div>
							<Button variant='contained' onClick={() => fetchActivities()}>
								Search
							</Button>
							<Button
								variant='contained'
								onClick={() => {
									setSearch("");
									setFilterRange([dayjs().subtract(1, "month"), dayjs()]);
									fetchActivities("", [dayjs().subtract(1, "month"), dayjs()]);
								}}>
								Reset
							</Button>
						</div>
					</div>
				</Card>
				<Card
					className='card filter-form'
					sx={{ padding: "1.5rem", justifyContent: "space-between" }}>
					<div
						className='heading btns'
						style={{ margin: 0 }}
						justifyContent={"center"}>
						<span>EXPORT DATA</span>
					</div>
					<DateRange
						range={downloadRange}
						onChange={(range) => setDownloadRange(range)}
					/>
					<div className='btns'>
						<div>
							<Button
								variant='contained'
								onClick={async () => {
									const { data } = await saveReport({
										start_date: downloadRange[0].format(format),
										end_date: downloadRange[1].format(format),
									});
									fileDowload(data, "reports.csv");
								}}>
								Download
							</Button>
						</div>
					</div>
				</Card>
			</Stack>
			<TableContainer className='activity-table card data-table'>
				<Table aria-label='activity table'>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: "120px" }}>Date Time</TableCell>
							<TableCell sx={{ width: "50px" }}>Type</TableCell>
							<TableCell>Entity</TableCell>
							<TableCell>Activity</TableCell>
							<TableCell sx={{ width: "50px" }}>Action</TableCell>
							<TableCell sx={{ width: "50px" }}>Actor</TableCell>
							<TableCell>Notes</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{activities.map((activity) => {
							const { id, time, entityType, name, type, entity, actor, notes } =
								activity;
							return (
								<TableRow key={id} className='row'>
									<TableCell sx={{ width: "120px" }}>
										{formatDate(time)}
									</TableCell>
									<TableCell
										align='left'
										className='hover'
										onClick={() => onSelectActivity(activity)}
										sx={{ width: "50px" }}>
										{entityType}
									</TableCell>
									<TableCell
										align='left'
										className='hover'
										onClick={() => onSelectActivity(activity)}>
										{entity}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectActivity(activity)}>
										{name}
									</TableCell>
									<TableCell
										className='hover'
										sx={{ width: "50px" }}
										onClick={() => onSelectActivity(activity)}>
										{type}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectActivity(activity)}
										sx={{ width: "50px" }}>
										{actor}
									</TableCell>
									<TableCell
										className='hover'
										onClick={() => onSelectActivity(activity)}>
										{notes}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				{render()}
			</TableContainer>
		</div>
	);
}
