import * as React from "react";

import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EPEXGraph from "./Charts/EPEX";
import IntensityGraph from "./Charts/Intensity";
import PriceGraph from "./Charts/Price";
import { formatDate } from "../Common/utilityFns";
import { getDashboardChartById } from "../../../Store/dashboard";
import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";

//import CardActions from "@mui/material/CardActions";

//import Button from "@mui/material/Button";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))({
	[`& .${tooltipClasses.tooltip}`]: {
	  maxWidth: 'none',
	},
  });

export default function OutlinedCard({ card: chartId }) {
	const navigate = useNavigate();

	const { fullScreen } = useOutletContext();

	const slice = useSelector(getDashboardChartById(chartId));

	// console.log(slice, "aaaaaaa", chartId);

	const name = slice?.activityinfo?.data?.roomdetails?.name ?? "";

	const recipeStatus =
		slice?.activityinfo?.type === "pushed" ? "PUSHED" : "PROCESSED";

	const time = slice?.activityinfo?.time
		? formatDate(slice?.activityinfo?.time)
		: "";

	const isBaseRecipe = slice?.activityinfo?.data?.baseRecipe
		? "Base"
		: "Modified";

	const baseRecipeTotalMoles =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.base_total_moles ?? "";
	const modifiedRecipeTotalMoles =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.modified_total_moles ?? "";

	const baseRecipeTotalCost =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.base_total_cost ?? "";

	const modifiedRecipeTotalCost =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.modified_total_cost ?? "";

	const molesDiffPercent =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison
			?.molesDiff_percentage ?? "";

	const savings =
		slice?.recipedata?.optimizerData?.recipe_data?.comparison?.savings ?? "";
	const baserecipeName =
		slice?.recipedata?.baseRecipeName ?? "";
	const baserecipeId =
		slice?.recipedata?.baseRecipeId ?? "";
	const modifiedrecipeName =
		slice?.recipedata?.name ?? "";

	const mode =
		slice?.activityinfo?.data?.roomdetails?.mode ?? "";

	return (
		<Box className='card'>
			<Card variant='outlined' className='card'>
				<CardContent>
					<div className='title'>
						<p>Room : {name} {mode ? `( ${mode} )` : ''}</p>
					</div>
					<table>
						<tbody>
							<tr>
								<td>
									<div className='chart-title'>Intensity Chart</div>
								</td>
								<td>
									<div className='info-title'>Information</div>
								</td>
							</tr>
							<tr>
								<td
									onClick={() =>
										navigate("/dashboard/" + chartId + "/intensity-chart")
									}>
									<IntensityGraph chartId={chartId} />
								</td>
								<td>
									<Stack direction={"row"} className='info'>
										<div>
											<table>
												<tbody>
													<tr>
														<td
															className='info-left'
															style={{ textDecoration: "underline" }}>
															Recipe
														</td>
													</tr>
													<tr>
														<td className='info-left'>&nbsp;&nbsp;&nbsp;Base</td>
														<td>: {baserecipeId ? <Link className="link-styles" to={`/recipes/${baserecipeId}`}>{baserecipeName}</Link> : baserecipeName}</td>
													</tr>
													<tr>
														<td className='info-left'>&nbsp;&nbsp;&nbsp;Modified</td>
														<td style={{maxWidth:'350px',wordBreak:'break-word',whiteSpace: 'nowrap',overflow:'hidden',textOverflow: 'ellipsis'}}>: <NoMaxWidthTooltip title={modifiedrecipeName} placement="bottom-end" arrow>{modifiedrecipeName}</NoMaxWidthTooltip></td>
													</tr>
													<tr>
														<td className='info-left'>&nbsp;&nbsp;&nbsp;Status</td>
														<td>: {recipeStatus}</td>
													</tr>
													<tr>
														<td className='info-left'>&nbsp;&nbsp;&nbsp;Push Time</td>
														<td>: {time}</td>
													</tr>
													{recipeStatus === "PUSHED" ? (
														<tr>
															<td className='info-left'>&nbsp;&nbsp;&nbsp;Pushed</td>
															<td>: {isBaseRecipe}</td>
														</tr>
													) : null}
													<tr>
														<td
															className='info-left'
															style={{ textDecoration: "underline" }}>
															Moles
														</td>
													</tr>
													<tr>
														<td className='info-left'>
															&nbsp;&nbsp;&nbsp;Base Recipe - Total
														</td>
														<td>: {Number(baseRecipeTotalMoles).toFixed(3)}</td>
													</tr>
													<tr>
														<td className='info-left'>
															&nbsp;&nbsp;&nbsp;Modified Recipe - Total
														</td>
														<td>
															: {Number(modifiedRecipeTotalMoles).toFixed(3)}
														</td>
													</tr>
													<tr>
														<td className='info-left'>
															&nbsp;&nbsp;&nbsp;Difference
														</td>
														<td>: {molesDiffPercent}%</td>
													</tr>

													<tr>
														<td
															className='info-left'
														>
															<span style={{ textDecoration: "underline" }}>Cost</span> <span>(&#163;)</span>
														</td>
													</tr>
													<tr>
														<td className='info-left'>
															&nbsp;&nbsp;&nbsp;Base Recipe - Total
														</td>
														<td>: {Number(baseRecipeTotalCost).toFixed(3)}</td>
													</tr>
													<tr>
														<td className='info-left'>
															&nbsp;&nbsp;&nbsp;Modified Recipe - Total
														</td>
														<td>
															: {Number(modifiedRecipeTotalCost).toFixed(3)}
														</td>
													</tr>
													<tr>
														<td className='info-left'>
															&nbsp;&nbsp;&nbsp;Savings - Total
														</td>
														<td>: {Number(savings).toFixed(3)}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</Stack>
								</td>
							</tr>
							<tr>
								<td>
									<div className='chart-title'>EPEX Chart</div>
								</td>
								<td>
									<div className='chart-title'>Price Chart</div>
								</td>
							</tr>
							<tr>
								<td
									onClick={() =>
										navigate("/dashboard/" + chartId + "/epex-chart")
									}>
									<EPEXGraph chartId={chartId} />
								</td>
								<td
									onClick={() =>
										navigate("/dashboard/" + chartId + "/price-chart")
									}>
									<PriceGraph chartId={chartId} />
								</td>
							</tr>
						</tbody>
					</table>
				</CardContent>
				{/* <CardActions>
						<Button size='small'>Learn More</Button>
					</CardActions> */}
			</Card>
		</Box>
	);
}
