import { useLocation, useParams } from "react-router-dom";

import { Button } from "@mui/material";
import Chart from "chart.js/auto";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import React from "react";
import { getRecipeDataById } from "../../../../Store/dashboard";
import { useSelector } from "react-redux";

// Try to eleminate importing auto CHart

// https://upmostly.com/tutorials/how-to-use-chart-js-with-react

const LineChart = ({ chartId }) => {
	// const { state = {} } = useLocation();
	// const { zoom = false } = state || {};

	const { id } = useParams();

	const recipe_data = useSelector(getRecipeDataById(id ? id : chartId));

	//let labels = Array.from({ length: 48 }, (v, i) => i);
	const isFullScreen = id !== undefined;
	// if (!isFullScreen) {
	// 	labels = Array.from({ length: 12 }, (v, i) => i * 4);
	// }
	const chartOptions = {
		responsive: true,
	};
	const data = {
		labels: recipe_data?.comparison?.data?.map(
			(obj) => obj.slot
		) ?? [],
		datasets: [
			{
				label: "Base Recipe",
				backgroundColor: "rgb(255, 99, 132)",
				borderColor: "rgb(255, 99, 132)",
				data:
					recipe_data?.comparison?.data?.map(
						(obj) => obj.base_settlement_price
					) ?? [],
			},
			{
				label: "Modified Recipe",
				backgroundColor: "orange",
				borderColor: "orange",
				data:
					recipe_data?.comparison?.data?.map(
						(obj) => obj.modified_settlement_price
					) ?? [],
			},
		],
	};
	return (
		// <div className={zoom ? "zoom card-chart" : "zoom-out card-chart"}>
		// 	{zoom ? (
		// 		<Button
		// 			variant='contained'
		// 			component={Link}
		// 			to='/dashboard'
		// 			className='btn'
		// 			startIcon={<ChevronLeftIcon />}>
		// 			BACK
		// 		</Button>
		// 	) : null}
		<div
			style={{ width: "95%" }}
			className={isFullScreen ? "card full-page-chart" : ""}>
			<Line data={data} className={isFullScreen ? "chart" : "chart zoom-out"} />
		</div>
		// </div>
	);
};

export default LineChart;
